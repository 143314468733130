<template>
  <el-form-item
      :class="[field.label && field.label.labelPosition ? 'el-form--labelPosition-' + field.label.labelPosition : 'el-form--labelPosition-top']"
      :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"
      :size="field.meta && field.meta.size ? field.meta.size : ''"
  >
    <template slot="label">
      <Tooltip
          :content="field.label && field.label.name ? $t(field.label.name) : ''"
          :labelTooltip="field.label.labelTooltip"
          v-if="field.label && field.label.labelTooltip"
      ></Tooltip>
      <span
          class="labelWrap"
          v-else
      >{{ field.label && field.label.name ? $t(field.label.name) : '' }}</span>
    </template>
    <div
        class="el-form-text"
        v-if="value && dictionaries && dictionaries[field.dictionary]"
    >
      <el-checkbox
          :disabled="meta && meta.disabled ?  meta.disabled : false"
          v-model="value"
      ></el-checkbox>
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </el-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';

export default {
  name: 'index',
  mixins: [abstractForm],
};
</script>

<style scoped>

</style>
